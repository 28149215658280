var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"activator-container",style:({
    position: _vm.absolute ? 'absolute' : undefined,
    top: _vm.positionTop ? _vm.positionTop : undefined,
    right: _vm.positionRight ? _vm.positionRight : undefined,
    bottom: _vm.positionBottom ? _vm.positionBottom : undefined,
    left: _vm.positionLeft ? _vm.positionLeft : undefined
  })},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.visible)?_c('v-btn',{ref:"activator",attrs:{"fab":"","width":"32","height":"32"}},[(_vm.icon)?_c('v-icon',{attrs:{"size":"20","color":"accent"},domProps:{"textContent":_vm._s(_vm.icon)}}):_vm._e()],1):_vm._e()],1),_c('Menu',{ref:"menuRef",attrs:{"activator":() => _vm.$refs.activator,"open-on-click":false,"close-on-click":false,"close-on-content-click":false,"nudge-left":_vm.nudgeLeft,"nudge-bottom":_vm.nudgeBottom,"nudge-right":_vm.nudgeRight,"nudge-top":_vm.nudgeTop,"bottom":_vm.bottom,"top":_vm.top,"right":_vm.right,"left":_vm.left,"offset-y":_vm.offsetY,"offset-x":_vm.offsetX,"min-width":280,"max-width":280,"border":"accent","pulse":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-pb-3"},[_vm._t("default",null,{"complete":_vm.complete}),_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-justify-between tw-mt-2 tw-mx-4"},[_c('div',{staticClass:"tw-text-xs"},[_vm._v(" "+_vm._s(_vm.index + 1)),_c('span',{staticClass:"grey--text text--lighten-5"},[_vm._v("/"+_vm._s(_vm.userGoals.length))])]),(!_vm.hideDismiss)?_c('v-btn',{staticClass:"tw--mx-2 tw--my-1",attrs:{"text":"","loading":_vm.completing,"small":""},on:{"click":_vm.complete}},[_vm._v(" Got it ")]):_vm._e()],1)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }