
import { TAG_COLOR_ORDER } from '@icepanel/app-canvas'
import { PermissionType, TagRequired } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as sort from '@/helpers/sort'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import BarItem from './item.vue'

@Component({
  components: {
    BarItem
  },
  name: 'TagBar'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  lastOverlayGroupId: string | null = null

  @Prop() readonly permission!: PermissionType

  @Ref() readonly barTagRefs?: BarItem[]

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentTagGroup () {
    return Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)
  }

  get currentGroupTags () {
    return Object.values(this.tagModule.tags).filter(o => o.groupId === this.currentTagGroup?.id).sort(sort.index)
  }

  get diagramTagCount () {
    return this.currentGroupTags.reduce<Record<string, number>>((p, c) => ({
      ...p,
      [c.id]: Object.values(this.currentDiagram?.objects || {}).filter(o => this.modelModule.objects[o.modelId]?.tagIds.includes(c.id)).length
    }), {})
  }

  get currentTagGroupTags () {
    return Object.values(this.tagModule.tags).filter(o => o.groupId === this.currentTagGroup?.id)
  }

  get currentTagGroupTagHandleIds () {
    return this.currentTagGroupTags.map(o => o.handleId)
  }

  get currentTagGroupPinned () {
    return this.currentTagGroupTagHandleIds.every(o => this.overlayIdsPinned.includes(o))
  }

  @Watch('overlayGroupId')
  onOverlayGroupIdChanged (overlayGroupId: string | null) {
    if (overlayGroupId) {
      this.lastOverlayGroupId = overlayGroupId
    }
  }

  mounted () {
    this.lastOverlayGroupId = this.overlayGroupId
  }

  async createTag () {
    if (!this.currentTagGroup) {
      return
    }

    const groupTags = Object
      .values(this.tagModule.tags)
      .sort((a, b) => a.index === b.index ? a.name > b.name ? 1 : -1 : a.index > b.index ? 1 : -1)
      .filter(o => o.groupId === this.currentTagGroup?.id)
    const lastGroupTag = groupTags[groupTags.length - 1]

    const usedColors = this.currentGroupTags.flat().map(o => o.color)
    const nextColor = TAG_COLOR_ORDER.find(o => !usedColors.includes(o)) || TAG_COLOR_ORDER[Math.floor((Math.random() * TAG_COLOR_ORDER.length))]

    const tagCreate: TagRequired = {
      color: nextColor,
      groupId: this.currentTagGroup.id,
      index: lastGroupTag ? lastGroupTag.index + 1 : 0,
      name: ''
    }
    const { tag, tagUpsert } = this.tagModule.generateTag(this.currentLandscape.id, this.currentVersion.id, tagCreate)
    this.tagModule.setTag(tag)
    this.editorModule.addToTaskQueue({
      func: () => this.tagModule.tagUpsert({
        landscapeId: this.currentLandscape.id,
        props: tagUpsert,
        tagId: tag.id,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks: [{
        id: tag.id,
        type: 'tag-delete'
      }, {
        route: this.$route,
        type: 'navigation'
      }],
      tasks: [{
        id: tag.id,
        props: tagCreate,
        type: 'tag-create'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    analytics.tagCreate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: tag.color,
      tagGroupIcon: this.currentTagGroup.icon,
      tagGroupName: this.currentTagGroup.name,
      tagName: tag.name,
      tagNameLength: tag.name.length
    })

    await this.$nextTick()

    this.barTagRefs?.find(o => o.tag.id === tag.id)?.tagChipRef.startEditing()
  }

  pinAll () {
    if (this.currentTagGroupPinned) {
      this.$replaceQuery({
        overlay_pin: this.$removeQueryArray(...this.currentTagGroupTagHandleIds)
      })

      if (this.currentTagGroup) {
        analytics.tagGroupUnpin.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          tagGroupIcon: this.currentTagGroup.icon,
          tagGroupName: this.currentTagGroup.name
        })
      }
    } else {
      this.$replaceQuery({
        overlay_pin: this.$unionQueryArray(...this.currentGroupTags.map(tag => tag.handleId))
      })

      if (this.currentTagGroup) {
        analytics.tagGroupPin.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          tagGroupIcon: this.currentTagGroup.icon,
          tagGroupName: this.currentTagGroup.name
        })
      }
    }
  }
}
