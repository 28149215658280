
import { TAG_COLOR_ACTIVE, TAG_COLOR_ORDER } from '@icepanel/app-canvas'
import { TagColor, Team } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Menu
  },
  name: 'TeamTagColorMenu'
})
export default class extends Vue {
  versionModule = getModule(VersionModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly team!: Team
  @Prop() readonly activator!: (() => HTMLElement | undefined) | undefined
  @Prop() readonly menuProps: any

  model: TagColor = 'black'

  get colors (): TagColor[][] {
    return [
      TAG_COLOR_ORDER.slice(0, 6),
      TAG_COLOR_ORDER.slice(6, 12)
    ]
  }

  get colorMap () {
    return TAG_COLOR_ACTIVE
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  @Watch('team.color')
  onTeamColorChanged (newVal: Team['color'], prevVal: Team['color']) {
    if (newVal !== prevVal) {
      this.model = newVal
    }
  }

  mounted () {
    this.model = this.team.color
  }

  resetModel () {
    this.model = this.team.color
  }

  opened () {
    analytics.teamColorMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      teamColor: this.team.color
    })
  }
}
