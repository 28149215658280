
import { UserGoalName } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import * as env from '@/helpers/env'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'

@Component({
  components: {
    Menu
  },
  name: 'UserGoalTooltip'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly name!: UserGoalName
  @Prop() readonly icon?: string
  @Prop() readonly progressData?: string

  @Prop({ default: 'left' }) readonly position!: 'top' | 'right' | 'bottom' | 'left'

  @Prop({ type: Boolean }) readonly disabled: boolean | undefined
  @Prop({ type: Boolean }) readonly absolute: boolean | undefined
  @Prop({ type: Boolean }) readonly hideDismiss: boolean | undefined

  @Prop() readonly left: boolean | undefined
  @Prop() readonly bottom: boolean | undefined
  @Prop() readonly right: boolean | undefined
  @Prop() readonly top: boolean | undefined

  @Prop() readonly offsetX: boolean | undefined
  @Prop() readonly offsetY: boolean | undefined

  @Prop() readonly nudgeBottom!: string | number
  @Prop() readonly nudgeLeft!: string | number
  @Prop() readonly nudgeRight!: string | number
  @Prop() readonly nudgeTop!: string | number

  @Prop() readonly positionBottom!: string | number
  @Prop() readonly positionLeft!: string | number
  @Prop() readonly positionRight!: string | number
  @Prop() readonly positionTop!: string | number

  completing = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get versionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentOrganizationId () {
    return this.currentLandscape.organizationId
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.versionId || o.tags.includes(this.versionId))
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get userGoals () {
    return this.userModule.currentUserGoals(this.currentLandscapePermission)
  }

  get userGoal () {
    return this.userGoals.find(o => o.name === this.name)
  }

  get currentUserGoal () {
    return this.userGoals.find(o => o.status === 'incomplete')
  }

  get active () {
    return this.currentUserGoal && this.userGoal && this.currentUserGoal.name === this.userGoal.name
  }

  get completed () {
    if (this.userGoal) {
      return this.userGoal.status === 'complete' || this.completing || (this.progressData && this.userGoal.progressData.includes(this.progressData))
    } else {
      return false
    }
  }

  get visible () {
    return this.active && !this.completed && !this.disabled
  }

  get index () {
    return this.userGoal ? this.userGoals.indexOf(this.userGoal) : undefined
  }

  @Watch('visible')
  async onVisibleChanged (visible: boolean) {
    if (visible) {
      this.menuRef.open()
      await this.$nextTick()
      this.updateDimensions()
    } else {
      this.menuRef.close()
    }
  }

  @Watch('positionTop')
  async onPositionTopChanged () {
    await this.$nextTick()
    this.updateDimensions()
  }

  @Watch('positionRight')
  async onPositionRightChanged () {
    await this.$nextTick()
    this.updateDimensions()
  }

  @Watch('positionBottom')
  async onPositionBottomChanged () {
    await this.$nextTick()
    this.updateDimensions()
  }

  @Watch('positionLeft')
  async onPositionLeftChanged () {
    await this.$nextTick()
    this.updateDimensions()
  }

  mounted () {
    if (this.visible) {
      this.menuRef.open()
    }
  }

  updateDimensions () {
    this.menuRef.updateDimensions()
  }

  async complete () {
    if (env.IS_SHARE_LINK && this.userGoal) {
      this.userModule.upsertUserGoal({
        ...this.userGoal,
        status: 'complete'
      })

      try {
        const userGoalsComplete = JSON.parse(localStorage.getItem('shareLinkUserGoalsComplete') || '[]')
        localStorage.setItem('shareLinkUserGoalsComplete', JSON.stringify([...new Set([...userGoalsComplete, this.userGoal.name])]))
      } catch (err) {
        console.error(err)
      }
    } else if (!this.completing && this.active && this.userGoal) {
      try {
        this.completing = true

        const updatedUserGoal = await this.userModule.userGoalProgress({
          props: this.progressData ? { data: [this.progressData] } : undefined,
          userGoalId: this.userGoal.id
        })

        analytics.userGoalProgress.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          userGoalName: updatedUserGoal.name,
          userGoalProgressCount: updatedUserGoal.progress,
          userGoalProgressData: updatedUserGoal.progressData,
          userGoalStepCount: updatedUserGoal.steps
        })
        if (updatedUserGoal.status === 'complete') {
          analytics.userGoalComplete.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId],
            userGoalName: updatedUserGoal.name,
            userGoalProgressCount: updatedUserGoal.progress,
            userGoalProgressData: updatedUserGoal.progressData,
            userGoalStepCount: updatedUserGoal.steps
          })
        }

        this.completing = false

        this.$emit('complete')
      } catch (err: any) {
        this.completing = false
        this.alertModule.pushAlert({
          color: 'error',
          message: err.message
        })
      }
    }
  }
}
