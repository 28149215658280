
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { TeamModule } from '../../store'
import BarItem from './item.vue'

@Component({
  components: {
    BarItem
  },
  name: 'TeamBar'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get teams () {
    return this.teamModule.teams
  }

  get teamIds () {
    return this.teams.map(o => o.id)
  }

  get teamCount () {
    return this.teams.reduce<Record<string, number>>((p, c) => ({
      ...p,
      [c.id]: Object.values(this.currentDiagram?.objects || {}).filter(o => this.modelModule.objects[o.modelId]?.teamIds.includes(c.id)).length
    }), {})
  }

  get teamsPinned () {
    return this.teamIds.every(o => this.overlayIdsPinned.includes(o))
  }

  pinAll () {
    if (this.teamsPinned) {
      this.$replaceQuery({
        overlay_pin: this.$removeQueryArray(...this.teamIds)
      })

      analytics.teamUnpinAll.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    } else {
      this.$replaceQuery({
        overlay_pin: this.$unionQueryArray(...this.teamIds)
      })

      analytics.teamPinAll.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
