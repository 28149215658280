
import { ModelObject, PermissionType, Task, TaskModelObjectUpdate } from '@icepanel/platform-api-client'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import { TeamModule } from '../store'
import MemberList from './member-list.vue'

@Component({
  components: {
    MemberList,
    Menu
  },
  name: 'TeamPicker'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly model!: ModelObject | ModelObject[]
  @Prop() readonly permission!: PermissionType
  @Prop({ default: 1 }) readonly showTeamNames!: number | boolean

  @Ref() readonly teamPickerMenuRef?: Menu

  visible = false
  confirmEditAccessLossTeamId: string | null = null
  selectedIndexes: number[] = []

  searchTerm = ''
  searchTermModel = ''
  searchFocused = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get teamPickerMenu () {
    return this.$queryValue('team_picker_menu')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get objects () {
    return this.model instanceof Array ? this.model : this.model ? [this.model] : []
  }

  get teams () {
    return this.teamModule.teams
      .filter(o => !this.searchTerm || o.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
      .filter(o => this.permission !== 'read' || this.objects.some(m => m.teamIds.includes(o.id)))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(t => ({
        ...t,
        assigned: this.objects.length && this.objects.every(o => o.teamIds.includes(t.id)),
        assignedSome: this.objects.length && !this.objects.every(o => o.teamIds.includes(t.id)) && this.objects.some(o => o.teamIds.includes(t.id)),
        ownedObjectIds: t.modelObjectHandleIds
          .map(id => {
            const modelObjectId = this.modelModule.objectHandles[id]
            return modelObjectId ? this.modelModule.objects[modelObjectId] : undefined
          })
          .filter((o): o is ModelObject => !!o && o.versionId === this.currentVersion?.id)
      }))
  }

  get teamsAssigned () {
    return this.teams.filter(o => o.assigned || o.assignedSome)
  }

  get multiple () {
    return this.teams.some(o => o.assignedSome)
  }

  get teamsAssignedWithUser () {
    const userTeamIds = this.teamModule.userTeams.map(o => o.id)
    return this.teamsAssigned.filter(o => userTeamIds.includes(o.id))
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }

  @Watch('selectedIndexes')
  async onSelectedIndexesChanged () {
    for (let i = 0; i < 100; i++) {
      this.teamPickerMenuRef?.updateDimensions()
      await new Promise(resolve => setTimeout(resolve, 10))
    }
  }

  open () {
    this.searchTerm = ''
    this.searchTermModel = ''
  }

  opened () {
    analytics.teamPickerMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelObjectTypes: this.objects.map(o => o.type),
      organizationId: [this.currentLandscape.organizationId]
    })

    this.visible = true
  }

  closed () {
    this.visible = false
    this.selectedIndexes = []
  }

  resetTaskLists () {
    this.editorModule.resetTaskLists()
  }

  toggleIndex (index: number) {
    if (this.selectedIndexes.includes(index)) {
      this.selectedIndexes = this.selectedIndexes.filter(o => o !== index)
    } else {
      this.selectedIndexes = [...new Set([...this.selectedIndexes, index])]
    }
  }

  setTeam (teamId: string, value: boolean) {
    const tasks: Task[] = []
    const revertTasks: Task[] = []

    const func: (() => Promise<any>)[] = []

    if (this.currentDiagram?.status === 'draft') {
      const teamUpdates: TaskModelObjectUpdate[] = []

      this.objects.forEach(o => {
        if (value && !o.teamIds.includes(teamId)) {
          teamUpdates.push({
            id: o.id,
            props: {
              teamIds: [...o.teamIds, teamId]
            },
            type: 'model-object-update'
          })
        } else if (!value && o.teamIds.includes(teamId)) {
          teamUpdates.push({
            id: o.id,
            props: {
              teamIds: o.teamIds.filter(o => o !== teamId)
            },
            type: 'model-object-update'
          })
        }
      })

      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: teamUpdates.map(o => ({
              id: o.id,
              props: {
                teamIds: this.modelModule.objects[o.id].teamIds
              },
              type: o.type
            }))
          }
        },
        type: 'diagram-content-update'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: teamUpdates
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)

      func.push(() => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      }))

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      this.objects.forEach(o => {
        let teamIds = [...o.teamIds]

        if (value && !teamIds.includes(teamId)) {
          teamIds.push(teamId)

          analytics.teamObjectAdd.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelObjectType: o.type,
            organizationId: [this.currentLandscape.organizationId],
            teamId: [teamId]
          })
        } else if (!value && teamIds.includes(teamId)) {
          teamIds = teamIds.filter(o => o !== teamId)

          analytics.teamObjectRemove.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelObjectType: o.type,
            organizationId: [this.currentLandscape.organizationId],
            teamId: [teamId]
          })
        }

        revertTasks.push({
          id: o.id,
          props: {
            teamIds: o.teamIds
          },
          type: 'model-object-update'
        })

        const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, {
          teamIds
        })
        this.modelModule.setObjectVersion(object)

        func.push(() => this.modelModule.objectUpdate({
          landscapeId: this.currentLandscape.id,
          objectId: o.id,
          props: objectUpdate,
          versionId: this.currentVersion.id
        }))

        tasks.push({
          id: object.id,
          props: objectUpdate,
          type: 'model-object-update'
        })
      })
    }

    this.editorModule.addToTaskQueue({ func })

    this.editorModule.addTaskList({
      revertTasks: [{
        tasks: revertTasks,
        type: 'batch'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            team_picker_menu: null
          }
        },
        type: 'navigation'
      }],
      tasks: [{
        tasks,
        type: 'batch'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            team_picker_menu: null
          }
        },
        type: 'navigation'
      }]
    })
  }
}
