
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { PermissionType, Tag, TagGroup, TagPartial, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'
import TagColorMenu from '../color-menu.vue'
import TagChip from '../tag.vue'

@Component({
  components: {
    TagChip,
    TagColorMenu
  },
  name: 'TagBarItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly tagChipRef!: TagChip
  @Ref() readonly contentRef!: HTMLElement
  @Ref() readonly tagColorMenuRef!: TagColorMenu

  @Prop() readonly tag!: Tag
  @Prop() readonly tagGroup!: TagGroup
  @Prop() readonly permission!: PermissionType
  @Prop() readonly count?: number

  editing = false

  hoverTag = false
  hoverMenu = false
  hoverFocus = false
  hoverHide = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get pinned () {
    return this.$queryArray('overlay_pin').includes(this.tag.handleId)
  }

  get hidden () {
    return this.$queryArray('overlay_hide').includes(this.tag.handleId)
  }

  get focused () {
    return this.$queryArray('overlay_focus').includes(this.tag.handleId)
  }

  get icon () {
    if (this.hidden) {
      return '$fas-eye-slash'
    } else if (this.pinned || this.hoverTag) {
      return '$fas-map-pin'
    } else {
      return icons.tagGroups[this.tagGroup.icon]
    }
  }

  get colorMap () {
    if (this.hidden) {
      return TAG_COLOR_INACTIVE
    } else if (this.hoverTag || this.hoverMenu || this.editing || this.pinned) {
      return TAG_COLOR_ACTIVE
    } else {
      return TAG_COLOR_INACTIVE
    }
  }

  get colorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get highlight () {
    return !this.hidden && this.hoverTag
  }

  get hidePreview () {
    return !this.hidden && this.hoverHide
  }

  get unhidePreview () {
    return this.hidden && (this.hoverTag || this.hoverHide)
  }

  get focusPreview () {
    return !this.focused && this.hoverFocus
  }

  get unfocusPreview () {
    return this.focused && (this.hoverTag || this.hoverFocus)
  }

  @Watch('highlight')
  onHighlightChanged (highlight: boolean) {
    this.editorModule.setHighlight({
      enabled: highlight,
      id: this.tag.handleId
    })
  }

  @Watch('hidePreview')
  onHidePreviewChanged (hidePreview: boolean) {
    this.editorModule.setHidePreview({
      enabled: hidePreview,
      id: this.tag.handleId
    })
  }

  @Watch('unhidePreview')
  onUnhidePreviewChanged (unhidePreview: boolean) {
    this.editorModule.setUnhidePreview({
      enabled: unhidePreview,
      id: this.tag.handleId
    })
  }

  @Watch('focusPreview')
  onFocusPreviewChanged (focusPreview: boolean) {
    this.editorModule.setFocusPreview({
      enabled: focusPreview,
      id: this.tag.handleId
    })
  }

  @Watch('unfocusPreview')
  onUnfocusPreviewChanged (unfocusPreview: boolean) {
    this.editorModule.setUnfocusPreview({
      enabled: unfocusPreview,
      id: this.tag.handleId
    })
  }

  clickTag () {
    if (this.hidden) {
      this.unhide()
    } else if (this.focused) {
      this.unfocus()
    } else if (this.pinned) {
      this.unpin()
    } else if (!this.overlayIdsFocused.length) {
      this.pin()
    }
  }

  pin () {
    this.$replaceQuery({
      overlay_pin: this.$unionQueryArray(this.tag.handleId)
    })

    analytics.tagPin.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: this.tag.color,
      tagGroupIcon: this.tagGroup.icon,
      tagGroupName: this.tagGroup.name,
      tagName: this.tag.name
    })
  }

  unpin () {
    this.$replaceQuery({
      overlay_pin: this.$removeQueryArray(this.tag.handleId)
    })

    analytics.tagUnpin.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: this.tag.color,
      tagGroupIcon: this.tagGroup.icon,
      tagGroupName: this.tagGroup.name,
      tagName: this.tag.name
    })
  }

  clickHide () {
    if (this.hidden) {
      this.unhide()
    } else {
      this.hide()
    }
  }

  hide () {
    this.$replaceQuery({
      overlay_focus: undefined,
      overlay_hide: this.$unionQueryArray(this.tag.handleId),
      overlay_pin: this.$removeQueryArray(this.tag.handleId)
    })

    analytics.tagHide.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: this.tag.color,
      tagGroupIcon: this.tagGroup.icon,
      tagGroupName: this.tagGroup.name,
      tagName: this.tag.name
    })
  }

  unhide () {
    this.$replaceQuery({
      overlay_focus: undefined,
      overlay_hide: this.$removeQueryArray(this.tag.handleId)
    })

    analytics.tagUnhide.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: this.tag.color,
      tagGroupIcon: this.tagGroup.icon,
      tagGroupName: this.tagGroup.name,
      tagName: this.tag.name
    })
  }

  clickFocus () {
    if (this.focused) {
      this.unfocus()
    } else {
      this.focus()
    }
  }

  unfocus () {
    this.$replaceQuery({
      overlay_focus: this.$removeQueryArray(this.tag.handleId),
      overlay_hide: undefined,
      overlay_pin: this.$removeQueryArray(this.tag.handleId)
    })

    analytics.tagUnfocus.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: this.tag.color,
      tagGroupIcon: this.tagGroup.icon,
      tagGroupName: this.tagGroup.name,
      tagName: this.tag.name
    })
  }

  focus () {
    this.$replaceQuery({
      overlay_focus: [this.tag.handleId],
      overlay_hide: undefined,
      overlay_pin: [this.tag.handleId]
    })

    analytics.tagFocus.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: this.tag.color,
      tagGroupIcon: this.tagGroup.icon,
      tagGroupName: this.tagGroup.name,
      tagName: this.tag.name
    })
  }

  updateTag (props: TagPartial) {
    const revertTasks: Task[] = [{
      id: this.tag.id,
      props: {
        color: this.tag.color,
        groupId: this.tag.groupId,
        index: this.tag.index,
        name: this.tag.name
      },
      type: 'tag-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { tag, tagUpdate } = this.tagModule.generateTagCommit(this.tag.id, props)
    this.tagModule.setTagVersion(tag)
    this.editorModule.addToTaskQueue({
      func: () => this.tagModule.tagUpdate({
        landscapeId: this.currentLandscape.id,
        props: tagUpdate,
        tagId: this.tag.id,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: tag.id,
        props: tagUpdate,
        type: 'tag-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    analytics.tagUpdate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: props.color,
      tagGroupIcon: this.tagGroup.icon,
      tagGroupName: this.tagGroup.name,
      tagName: props.name,
      tagNameLength: props.name?.length
    })
  }

  mouseOver (e: MouseEvent) {
    if (e.buttons === 0) {
      this.hoverTag = true
    }
  }

  mouseLeave () {
    this.hoverTag = false
  }
}
