
import { PermissionType, TagGroup, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { getModule } from 'vuex-module-decorators'

import cumulativeOffset from '@/helpers/cumulative-offset'
import * as sort from '@/helpers/sort'
import { DiagramModule } from '@/modules/diagram/store'
import * as editorAnalytics from '@/modules/editor/helpers/analytics'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'
import TagPickerGroupIconMenu from '../tag-picker/group-icon-menu.vue'

@Component({
  components: {
    Draggable,
    TagPickerGroupIconMenu
  },
  name: 'TagGroupTabs'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly nameInput!: HTMLElement[]
  @Ref() readonly tabsRef!: any
  @Ref() readonly groupIconMenu!: TagPickerGroupIconMenu

  @Prop() readonly permission!: PermissionType

  selectedIndex = -1
  editingIndex = -1
  reordering = false
  reorderScrollDirection: 'left' | 'right' | undefined
  reorderMouseInterval?: number

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentTagGroup () {
    return Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)
  }

  get iconMap () {
    return icons.tagGroups
  }

  get tagGroups () {
    return Object.values(this.tagModule.tagGroups).sort(sort.index)
  }

  get defaultTagGroup () {
    return Object.values(this.tagModule.tagGroups).reduce<TagGroup | undefined>((c, p) => c && c.index < p.index ? c : p, undefined)
  }

  get groupTagCount () {
    return this.tagGroups.reduce<Record<string, number>>((p, c) => ({
      ...p,
      [c.id]: Object.values(this.tagModule?.tags || {}).filter(o => o.groupId === c.id).length
    }), {})
  }

  @Watch('currentTagGroup')
  async onCurrentTabGroupChanged (currentTagGroup?: TagGroup, prevTagGroup?: TagGroup) {
    if (currentTagGroup?.id !== prevTagGroup?.id || currentTagGroup?.index !== prevTagGroup?.index) {
      const index = this.tagGroups.findIndex(o => o.id === currentTagGroup?.id)
      if (index > -1) {
        this.selectedIndex = index
        if (this.tagGroups[index]?.name.trim() === '') {
          this.startEditing(index)
        } else {
          await this.$nextTick()
          await this.refreshTabs()
        }
      } else {
        this.selectedIndex = -1
      }

      if (currentTagGroup) {
        this.editorModule.setOverlayBarTagGroups({
          [this.currentLandscape.id]: currentTagGroup.handleId
        })
      }

      if (this.currentDiagram && currentTagGroup) {
        editorAnalytics.editorOverlayBarTagGroup.track(this, {
          diagramType: this.currentDiagram.type,
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          tagGroupIcon: currentTagGroup.icon,
          tagGroupName: currentTagGroup.name
        })
      }
    }
  }

  async mounted () {
    const index = this.tagGroups.findIndex(o => o.id === this.currentTagGroup?.id)
    if (index > -1) {
      this.selectedIndex = index
      if (this.tagGroups[index]?.name.trim() === '') {
        this.startEditing(index)
      } else {
        await this.$nextTick()
        await this.refreshTabs()
      }
    } else {
      this.selectedIndex = -1
    }

    if (this.currentTagGroup) {
      this.editorModule.setOverlayBarTagGroups({
        [this.currentLandscape.id]: this.currentTagGroup.handleId
      })
    }

    if (this.currentTagGroup && this.currentDiagram) {
      editorAnalytics.editorOverlayBarTagGroup.track(this, {
        diagramType: this.currentDiagram.type,
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagGroupIcon: this.currentTagGroup.icon,
        tagGroupName: this.currentTagGroup.name
      })
    }
  }

  destroyed () {
    this.reorderEnd()
  }

  reorderStart () {
    this.reordering = true

    const tabBar = this.tabsRef.$children[0]

    clearInterval(this.reorderMouseInterval)
    this.reorderMouseInterval = window.setInterval(() => {
      if (this.reorderScrollDirection === 'left') {
        tabBar.scrollOffset = Math.max(0, tabBar.scrollOffset - 20)
      } else if (this.reorderScrollDirection === 'right') {
        const tabBarElement = tabBar.$el as HTMLElement
        const slideGroupWrapper = tabBarElement.querySelector('.v-slide-group__wrapper')
        const slideGroupContent = slideGroupWrapper?.querySelector('.v-slide-group__content')
        const maxScrollOffset = slideGroupContent && slideGroupWrapper ? slideGroupContent.clientWidth - slideGroupWrapper.clientWidth : 0
        tabBar.scrollOffset = Math.min(maxScrollOffset, tabBar.scrollOffset + 20)
      }
    }, 100)
  }

  reorderMove (e: any) {
    const tabsOffset = cumulativeOffset(this.tabsRef.$el)
    if (e.relatedRect.left <= tabsOffset.left + 50) {
      this.reorderScrollDirection = 'left'
    } else if (e.relatedRect.right >= tabsOffset.left - 50 + this.tabsRef.$el.clientWidth) {
      this.reorderScrollDirection = 'right'
    } else {
      this.reorderScrollDirection = undefined
    }
  }

  reorderEnd () {
    clearInterval(this.reorderMouseInterval)
    this.reordering = false
  }

  async refreshTabs () {
    const selectedIndex = this.selectedIndex
    this.selectedIndex = -1
    await this.$nextTick()
    this.selectedIndex = selectedIndex
  }

  async startEditing (index: number) {
    if (this.editingIndex !== index) {
      this.selectedIndex = index
      this.editingIndex = index

      await this.$nextTick()

      const range = document.createRange()
      const el = this.nameInput[index]
      const sel = window.getSelection()
      range.selectNodeContents(el)
      sel?.removeAllRanges()
      sel?.addRange(range)
      el.focus()

      this.resize()
      await this.refreshTabs()
    }
  }

  nameKeypress (e: any) {
    if (e.key === 'Enter') {
      e.preventDefault()
      const target: HTMLElement = e.target
      target.blur()
    }
  }

  updateIndex ({ moved }: { moved?: { newIndex: number, oldIndex: number } }) {
    if (moved) {
      const tagGroupsOrdered = [...this.tagGroups]
      const element = tagGroupsOrdered[moved.oldIndex]
      tagGroupsOrdered.splice(moved.oldIndex, 1)
      tagGroupsOrdered.splice(Math.max(0, moved.newIndex), 0, element)

      const func: (() => Promise<any>)[] = []
      const tasks: Task[] = []
      const revertTasks: Task[] = []

      tagGroupsOrdered.forEach((o, index) => {
        revertTasks.push({
          id: o.id,
          props: {
            index: o.index
          },
          type: 'tag-group-update'
        })

        const { tagGroup, tagGroupUpdate } = this.tagModule.generateTagGroupCommit(o.id, { index })
        this.tagModule.setTagGroupVersion(tagGroup)

        func.push(() => this.tagModule.tagGroupUpdate({
          landscapeId: this.currentLandscape.id,
          props: tagGroupUpdate,
          tagGroupId: o.id,
          versionId: this.currentVersion.id
        }))

        tasks.push({
          id: tagGroup.id,
          props: tagGroupUpdate,
          type: 'tag-group-update'
        })
      })

      this.editorModule.addToTaskQueue({ func })

      this.editorModule.addTaskList({
        revertTasks: [{
          tasks: revertTasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }],
        tasks: [{
          tasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
  }

  async updateName (id: string, e: any) {
    const group = this.tagGroups.find(o => o.id === id)
    const newName = e.target.innerText.trim() || 'Unnamed group'

    if (group && group.name !== newName) {
      const revertTasks: Task[] = [{
        id: group.id,
        props: {
          name: group.name
        },
        type: 'tag-group-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { tagGroup, tagGroupUpdate } = this.tagModule.generateTagGroupCommit(group.id, {
        name: newName
      })
      this.tagModule.setTagGroupVersion(tagGroup)
      this.editorModule.addToTaskQueue({
        func: () => this.tagModule.tagGroupUpdate({
          landscapeId: this.currentLandscape.id,
          props: tagGroupUpdate,
          tagGroupId: group.id,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: tagGroup.id,
          props: tagGroupUpdate,
          type: 'tag-group-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })

      analytics.tagGroupUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagGroupName: newName,
        tagGroupNameLength: newName.length
      })
    }

    this.editingIndex = -1

    this.resize()
    await this.refreshTabs()
  }

  clickTagGroup (tagGroup: TagGroup) {
    if (this.overlayGroupId !== tagGroup.handleId) {
      this.$replaceQuery({
        overlay_group: tagGroup.handleId,
        overlay_pin: undefined
      })
    }
  }

  resize () {
    this.tabsRef?.$refs?.items?.onResize()
    this.tabsRef?.onResize()
  }
}
