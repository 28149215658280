
import { PermissionType, TagGroup, TagGroupRequired, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Animation from '@/components/animation.vue'
import * as sort from '@/helpers/sort'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'
import Tabs from './tabs.vue'

@Component({
  components: {
    Animation,
    OrganizationUpgradeMenu,
    Tabs
  },
  name: 'TagGroupBar'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly groupTabsRef!: Tabs

  @Prop() readonly permission!: PermissionType

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get tagGroupCount () {
    return Object.keys(this.tagModule.tagGroups).length
  }

  get tabGroupsLimitReached () {
    return this.tagGroupCount >= this.currentOrganizationLimits.tagGroups
  }

  async createTagGroup () {
    const tagGroups = Object.values(this.tagModule.tagGroups).sort(sort.index)
    const nextIndex = tagGroups.reduce((p, c) => c.index > p ? c.index : p, -1) + 1
    const tagGroupIcons = Object.keys(icons.tagGroups) as TagGroup['icon'][]
    const icon = tagGroupIcons[Math.floor((Math.random() * tagGroupIcons.length))]

    const tagGroupCreate: TagGroupRequired = {
      icon,
      index: nextIndex,
      name: ''
    }
    const { tagGroup, tagGroupUpsert } = this.tagModule.generateTagGroup(this.currentLandscape.id, this.currentVersion.id, tagGroupCreate)
    this.tagModule.setTagGroup(tagGroup)
    this.editorModule.addToTaskQueue({
      func: () => this.tagModule.tagGroupUpsert({
        landscapeId: this.currentLandscape.id,
        props: tagGroupUpsert,
        tagGroupId: tagGroup.id,
        versionId: this.currentVersion.id
      })
    })

    analytics.tagGroupCreate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagGroupIcon: tagGroup.icon,
      tagGroupName: tagGroup.name,
      tagGroupNameLength: tagGroup.name.length
    })

    await this.$nextTick()

    const revertTasks: Task[] = [{
      id: tagGroup.id,
      type: 'tag-group-delete'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    await this.$pushQuery({
      overlay_group: tagGroup.handleId,
      overlay_tab: 'tags'
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: tagGroup.id,
        props: tagGroupCreate,
        type: 'tag-group-create'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })
  }

  resize () {
    this.groupTabsRef?.resize()
  }
}
