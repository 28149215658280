
import { PermissionType } from '@icepanel/platform-api-client'
import { differenceInHours } from 'date-fns'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { OrganizationModule } from '@/modules/organization/store'
import { UserModule } from '@/modules/user/store'

@Component({
  name: 'TeamMemberList'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Prop() readonly organizationId!: string
  @Prop() readonly userIds!: string[]

  emailCopyTimer?: number
  emailCopied = false

  get userId () {
    return this.userModule.user?.id
  }

  get users () {
    return this.userIds
      .map(id => ({
        id,
        user: this.organizationModule.organizationUsers[this.organizationId]?.[id]
      }))
      .filter(u => u.user && u.user.permission !== 'billing')
      .map(u => ({
        ...u.user,
        id: u.id,
        permissionType: this.permissionTypes.find(o => o.value === u.user.permission)
      }))
      .sort((a, b) => new Date(a.lastActiveAt) > new Date(b.lastActiveAt) ? -1 : 1)
  }

  get permissionTypes (): { icon: string, text: string, value: PermissionType }[] {
    return [
      {
        icon: '$fas-eye',
        text: 'Organization viewer',
        value: 'read'
      },
      {
        icon: '$fas-user-edit',
        text: 'Organization editor',
        value: 'write'
      },
      {
        icon: '$fas-user-cog',
        text: 'Organization admin',
        value: 'admin'
      }
    ]
  }

  get avatarBadgeColor () {
    return (lastActiveAt: string) => {
      const diff = differenceInHours(new Date(), new Date(lastActiveAt))
      if (diff <= 24) {
        return 'green'
      } else if (diff <= 7 * 24) {
        return 'orange'
      } else {
        return 'red'
      }
    }
  }

  destroyed () {
    this.resetCopyEmailTimer()
  }

  async copyEmail (email: string) {
    await navigator.clipboard.writeText(email)
    this.resetCopyEmailTimer()
    this.emailCopied = true
    this.emailCopyTimer = window.setTimeout(() => {
      this.resetCopyEmailTimer()
    }, 2000)
  }

  resetCopyEmailTimer () {
    clearTimeout(this.emailCopyTimer)
    this.emailCopied = false
  }
}
