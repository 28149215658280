
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { PermissionType, Task, Team, TeamPartial } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import TagChip from '@/modules/tag/components/tag.vue'
import { TagModule } from '@/modules/tag/store'
import TagColorMenu from '@/modules/team/components/color-menu.vue'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  components: {
    TagChip,
    TagColorMenu
  },
  name: 'TeamBarItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)

  @Ref() readonly tagChipRef!: TagChip
  @Ref() readonly tagContainer!: HTMLElement
  @Ref() readonly contentRef!: HTMLElement
  @Ref() readonly tagColorMenuRef!: TagColorMenu

  @Prop() readonly permission!: PermissionType

  @Prop() readonly team!: Team
  @Prop() readonly count?: number

  hoverTag = false
  hoverMenu = false
  hoverFocus = false
  hoverHide = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get pinned () {
    return this.$queryArray('overlay_pin').includes(this.team.id)
  }

  get hidden () {
    return this.$queryArray('overlay_hide').includes(this.team.id)
  }

  get focused () {
    return this.$queryArray('overlay_focus').includes(this.team.id)
  }

  get icon () {
    if (this.hidden) {
      return '$fas-eye-slash'
    } else if (this.pinned || this.hoverTag) {
      return '$fas-map-pin'
    } else {
      return '$fas-users'
    }
  }

  get colorMap () {
    if (this.hidden) {
      return TAG_COLOR_INACTIVE
    } else if (this.hoverTag || this.hoverMenu || this.pinned) {
      return TAG_COLOR_ACTIVE
    } else {
      return TAG_COLOR_INACTIVE
    }
  }

  get colorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get highlight () {
    return !this.hidden && this.hoverTag
  }

  get hidePreview () {
    return !this.hidden && this.hoverHide
  }

  get unhidePreview () {
    return this.hidden && (this.hoverTag || this.hoverHide)
  }

  get focusPreview () {
    return !this.focused && this.hoverFocus
  }

  get unfocusPreview () {
    return this.focused && (this.hoverTag || this.hoverFocus)
  }

  @Watch('highlight')
  onHighlightChanged (highlight: boolean) {
    this.editorModule.setHighlight({
      enabled: highlight,
      id: this.team.id
    })
  }

  @Watch('hidePreview')
  onHidePreviewChanged (hidePreview: boolean) {
    this.editorModule.setHidePreview({
      enabled: hidePreview,
      id: this.team.id
    })
  }

  @Watch('unhidePreview')
  onUnhidePreviewChanged (unhidePreview: boolean) {
    this.editorModule.setUnhidePreview({
      enabled: unhidePreview,
      id: this.team.id
    })
  }

  @Watch('focusPreview')
  onFocusPreviewChanged (focusPreview: boolean) {
    this.editorModule.setFocusPreview({
      enabled: focusPreview,
      id: this.team.id
    })
  }

  @Watch('unfocusPreview')
  onUnfocusPreviewChanged (unfocusPreview: boolean) {
    this.editorModule.setUnfocusPreview({
      enabled: unfocusPreview,
      id: this.team.id
    })
  }

  clickTag () {
    if (this.hidden) {
      this.unhide()
    } else if (this.focused) {
      this.unfocus()
    } else if (this.pinned) {
      this.unpin()
    } else if (!this.overlayIdsFocused.length) {
      this.pin()
    }
  }

  async updateTeamColor (props: TeamPartial) {
    const revertTasks: Task[] = [{
      id: this.team.id,
      props: {
        color: this.team.color
      },
      type: 'team-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { team, teamUpdate } = this.teamModule.generateTeamCommit(this.team.id, props)
    this.teamModule.setTeam({ team })
    this.editorModule.addToTaskQueue({
      func: () => this.teamModule.teamUpdate({
        organizationId: this.team.organizationId,
        teamId: this.team.id,
        update: {
          color: props.color
        }
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: team.id,
        props: teamUpdate,
        type: 'team-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    analytics.teamUpdate.track(this, {
      organizationId: [team.organizationId],
      teamColor: props.color,
      teamId: [team.id]
    })
  }

  pin () {
    this.$replaceQuery({
      overlay_pin: this.$unionQueryArray(this.team.id)
    })

    analytics.teamPin.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  unpin () {
    this.$replaceQuery({
      overlay_pin: this.$removeQueryArray(this.team.id)
    })

    analytics.teamUnpin.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  clickHide () {
    if (this.hidden) {
      this.unhide()
    } else {
      this.hide()
    }
  }

  hide () {
    this.$replaceQuery({
      overlay_focus: undefined,
      overlay_hide: this.$unionQueryArray(this.team.id),
      overlay_pin: this.$removeQueryArray(this.team.id)
    })

    analytics.teamHide.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  unhide () {
    this.$replaceQuery({
      overlay_focus: undefined,
      overlay_hide: this.$removeQueryArray(this.team.id)
    })

    analytics.teamUnhide.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  clickFocus () {
    if (this.focused) {
      this.unfocus()
    } else {
      this.focus()
    }
  }

  unfocus () {
    this.$replaceQuery({
      overlay_focus: this.$removeQueryArray(this.team.id),
      overlay_hide: undefined,
      overlay_pin: this.$removeQueryArray(this.team.id)
    })

    analytics.teamUnfocus.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  focus () {
    this.$replaceQuery({
      overlay_focus: [this.team.id],
      overlay_hide: undefined,
      overlay_pin: [this.team.id]
    })

    analytics.teamFocus.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  mouseOver (e: MouseEvent) {
    if (e.buttons === 0) {
      this.hoverTag = true
    }
  }

  mouseLeave () {
    this.hoverTag = false
  }
}
